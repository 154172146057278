import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getFormById,
  getTopForms,
  getPreviousForms,
  getAllForms,
  getMyForms,
} from "../../service/formService";

export const fetchFormData = createAsyncThunk(
  "form/fetchFormData",
  async (formId, { getState, rejectWithValue }) => {
    const state = getState();

    if (state.form.currentFormId === formId) {
      return rejectWithValue("Form already loaded");
    }

    try {
      const formData = await getFormById(formId);
      return {
        formId,
        title: formData.title,
        description: formData.description,
        fields: formData.questions.map((q) => ({ ...q, key: q._id })),
      };
    } catch (error) {
      return rejectWithValue("Error fetching form data.");
    }
  }
);

export const fetchTopForms = createAsyncThunk(
  "form/fetchTopForms",
  async (limit, { rejectWithValue }) => {
    try {
      const forms = await getTopForms(limit);
      return forms;
    } catch (error) {
      return rejectWithValue("Error fetching top forms.");
    }
  }
);

export const fetchAllForms = createAsyncThunk(
  "forms/fetchAllForms",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAllForms();
      return response;
    } catch (error) {
      return rejectWithValue("Error fetching all forms.");
    }
  }
);

export const fetchMyForms = createAsyncThunk(
  "forms/fetchMyForms",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getMyForms();
      return response;
    } catch (error) {
      return rejectWithValue("Error fetching my forms.");
    }
  }
);

export const fetchPreviousForms = createAsyncThunk(
  "forms/fetchPreviousForms",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getPreviousForms();
      return response;
    } catch (error) {
      return rejectWithValue("Error fetching previous forms.");
    }
  }
);

const formSlice = createSlice({
  name: "form",
  initialState: {
    fields: [],
    title: "",
    description: "",
    currentFormId: "",
    forms: [],
    filteredForms: [],
    topForms: [],
    activeFilter: "getAllForms",
    loading: false,
    error: null,
  },
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setFields: (state, action) => {
      state.fields = action.payload;
    },
    addField: (state) => {
      state.fields.push({
        key: Date.now(),
        q_text: "",
        q_type: "textfield",
        options: [],
        required: false,
      });
    },
    updateField: (state, action) => {
      const { index, updatedField } = action.payload;
      state.fields[index] = updatedField;
    },
    removeField: (state, action) => {
      state.fields.splice(action.payload, 1);
    },
    resetForm: (state) => {
      state.fields = [];
      state.title = "";
      state.description = "";
      state.currentFormId = "";
    },
    setCurrentFormId: (state, action) => {
      state.currentFormId = action.payload;
    },
    setActiveFilter: (state, action) => {
      state.activeFilter = action.payload;
    },
    setFilteredForms: (state, action) => {
      state.filteredForms = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFormData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFormData.fulfilled, (state, action) => {
        state.loading = false;
        state.title = action.payload.title;
        state.description = action.payload.description;
        state.fields = action.payload.fields;
        state.currentFormId = action.payload.formId;
      })
      .addCase(fetchFormData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Error occurred";
      })
      .addCase(fetchTopForms.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTopForms.fulfilled, (state, action) => {
        state.loading = false;
        state.topForms = action.payload;
      })
      .addCase(fetchTopForms.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Error occurred";
      })
      .addCase(fetchAllForms.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllForms.fulfilled, (state, action) => {
        state.loading = false;
        state.forms = action.payload;
        state.filteredForms = action.payload;
      })
      .addCase(fetchAllForms.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchMyForms.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMyForms.fulfilled, (state, action) => {
        state.loading = false;
        state.forms = action.payload;
        state.filteredForms = action.payload;
      })
      .addCase(fetchMyForms.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchPreviousForms.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPreviousForms.fulfilled, (state, action) => {
        state.loading = false;
        state.forms = action.payload;
        state.filteredForms = action.payload;
      })
      .addCase(fetchPreviousForms.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setTitle,
  setDescription,
  setFields,
  addField,
  updateField,
  removeField,
  resetForm,
  setCurrentFormId,
  setActiveFilter,
  setFilteredForms,
} = formSlice.actions;

export default formSlice.reducer;
