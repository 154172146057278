import React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Logout from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";

const stringAvatar = (name) => {
  return {
    children: `${name[0].toUpperCase()}`,
  };
};

const AccountMenu = ({ userName, handleLogOut }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleLogOut();
  };

  const handleProfileClick = () => {
    try {
      const userData = localStorage.getItem("user");
      if (userData) {
        let user;
        try {
          user = JSON.parse(userData);
        } catch (error) {
          console.error("Error parsing user data:", error);
          user = null;
        }

        if (user && user.role === "admin") {
          navigate("/admin"); 
        } else if (user && user.role === "user") {
          navigate("/user");
        }
      } else {
        navigate("/"); 
      }
    } catch (error) {
      console.error("Error handling profile click:", error);
      navigate("/error"); 
    }
    handleClose();
  };

  const userObject = JSON.parse(userName);
  const username = userObject.username;

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
          <Avatar
            {...stringAvatar(`${username}`)}
            sx={{
              bgcolor: `var(--primary-light-color)`,
              color: `var(--font-primary-dark-color)`,
              border: 1,
            }}
          />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleProfileClick}>
          <Avatar sx={{ mr: 1 }} /> {username}
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          LogOut
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountMenu;
