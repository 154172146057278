import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateField, removeField } from "../../redux/slices/formSlice";
import debounce from "lodash/debounce";
import { alpha, styled } from "@mui/material/styles";
import { pink, grey } from "@mui/material/colors";
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  Box,
  TextField,
  FormGroup,
  Checkbox,
  FormControl,
  Button,
  IconButton,
  Switch,
  Typography,
  Snackbar,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AlertDialog from '../elements/AlertDialog'

const QuestionType = ({ index, fieldData, serialNumber }) => {
  const dispatch = useDispatch();
  const [localFieldData, setLocalFieldData] = useState(fieldData);
  const [questionType, setQuestionType] = useState(fieldData.q_type);
  const [questionText, setQuestionText] = useState(fieldData.q_text);
  const [radioOptions, setRadioOptions] = useState(fieldData.options);
  const [newOption, setNewOption] = useState("");
  const [isRequired, setIsRequired] = useState(fieldData.required);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const CustomRadio = styled(Radio)(({ theme }) => ({
    "&.Mui-checked": {
      color: grey[600],
    },
    "&.Mui-disabled": {
      color: grey[300],
    },
  }));

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: pink[600],
      "&:hover": {
        backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: pink[600],
    },
  }));

  useEffect(() => {
    if (fieldData !== localFieldData) {
      setLocalFieldData(fieldData);
    }
  }, [fieldData, localFieldData]);

  const debouncedUpdateField = useCallback(
    debounce((updatedField) => {
      dispatch(updateField({ index, updatedField }));
    }, 300),
    [dispatch, index]
  );

  const handleTypeChange = (event) => {
    const updatedType = event.target.value;

    let updatedOptions = [];
    if (updatedType === "radio" || updatedType === "checkbox") {
      updatedOptions = radioOptions;
    } else {
      updatedOptions = [];
    }

    setQuestionType(updatedType);
    setRadioOptions(updatedOptions);

    debouncedUpdateField({
      ...fieldData,
      q_type: updatedType,
      options: updatedOptions,
    });
  };

  const handleAddOption = () => {
    if (newOption.trim() !== "") {
      const isDuplicate = radioOptions.some(
        (option) => option.option_text === newOption
      );

      if (!isDuplicate) {
        const updatedOptions = [...radioOptions, { option_text: newOption }];
        setRadioOptions(updatedOptions);
        setNewOption("");
        debouncedUpdateField({ ...fieldData, options: updatedOptions });
      } else {
        setSnackbarMessage("Option already exists!");
        setSnackbarOpen(true);
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleRemoveOption = (optionIndex) => {
    const updatedOptions = radioOptions.filter(
      (_, index) => index !== optionIndex
    );
    setRadioOptions(updatedOptions);
    debouncedUpdateField({ ...fieldData, options: updatedOptions });
  };

  const handleNewOptionChange = (event) => {
    setNewOption(event.target.value);
  };

  const handleDelete = () => {
    if (isRequired) {
      setIsDialogOpen(true)
    } else {    
      dispatch(removeField(index));
    }
  };

  const handleTextChange = (event) => {
    const updatedText = event.target.value;
    setQuestionText(updatedText);
    debouncedUpdateField({ ...fieldData, q_text: updatedText });
  };

  const handleSwitchChange = (e) => {
    const updatedRequired = e.target.checked;
    setIsRequired(updatedRequired);
    debouncedUpdateField({ ...fieldData, required: updatedRequired });
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false)
  }

  const handleConfirmDelete = () => {
    dispatch(removeField(index))
    setIsDialogOpen(false)
  }

  return (
    <Box
      mt={2}
      sx={{
        mb: 3,
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        backgroundColor: "#ffffff",
        padding: "16px",
        border: "1px solid #e0e0e0",
      }}
    >
      <Box display="flex" alignItems="center">
        <Typography
          variant="h6"
          sx={{
            marginRight: 2,
            marginTop: -1.5,
          }}
        >
          {serialNumber}.
        </Typography>

        <TextField
          label="Question"
          variant="outlined"
          fullWidth
          value={questionText}
          onChange={handleTextChange}
          sx={{ mb: 2 }}
        />
      </Box>

      <FormControl component="fieldset">
        <RadioGroup
          row
          sx={{
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            border: "1px solid #e0e0e0",
            backgroundColor: "var(--primary-light-color)",
            padding: "5px",
          }}
          name={`questionType`}
          value={questionType}
          onChange={handleTypeChange}
        >
          <FormControlLabel
            value="textfield"
            control={<CustomRadio />}
            label="Text"
          />
          <FormControlLabel
            value="radio"
            control={<CustomRadio />}
            label="One Choice"
          />
          <FormControlLabel
            value="checkbox"
            control={<CustomRadio />}
            label="Multiple"
          />
          <FormControlLabel
            value="date"
            control={<CustomRadio />}
            label="Date"
          />
          <FormControlLabel
            value="number"
            control={<CustomRadio />}
            label="Number"
          />
        </RadioGroup>
      </FormControl>

      {questionType && (
        <Box
          sx={{
            borderRadius: "8px",
            marginTop: "20px",
            padding: "16px",
            backgroundColor: "#f9f9f9",
            border: "1px solid #e0e0e0",
          }}
        >
          {(questionType === "radio" || questionType === "checkbox") && (
            <FormControl component="fieldset">
              {questionType === "radio" ? (
                <RadioGroup
                  column
                  name="radioGroup"
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  {radioOptions.map((option, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      sx={{ mb: 1 }}
                    >
                      <FormControlLabel
                        value={option.option_text}
                        control={<CustomRadio disabled />}
                        label={option.option_text}
                      />
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleRemoveOption(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))}
                </RadioGroup>
              ) : (
                <FormGroup
                  column
                  name="checkboxGroup"
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  {radioOptions.map((option, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      sx={{ mb: 1 }}
                    >
                      <FormControlLabel
                        control={<Checkbox disabled />}
                        label={option.option_text}
                      />
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleRemoveOption(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))}
                </FormGroup>
              )}
              <Box mt={2} display="flex" alignItems="center">
                <TextField
                  label="New option"
                  variant="outlined"
                  value={newOption}
                  onChange={handleNewOptionChange}
                  sx={{ mr: 2 }}
                />
                <Button
                  onClick={handleAddOption}
                  variant="outlined"
                  sx={{
                    borderColor: "var(--secondary-dark-color)",
                    color: "var(--secondary-dark-color)",
                    "&:hover": {
                      borderColor: "var(--secondary-dark-color)",
                      backgroundColor: "rgba(0, 0, 0, 0.05)",
                    },
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  Add
                </Button>
              </Box>
            </FormControl>
          )}

          {questionType === "date" && (
            <TextField type="date" variant="outlined" disabled fullWidth />
          )}
          {questionType === "textfield" && (
            <TextField
              type="text"
              variant="outlined"
              label="Text"
              disabled
              fullWidth
            />
          )}
          {questionType === "number" && (
            <TextField
              type="number"
              label="Enter number"
              variant="outlined"
              disabled
              fullWidth
            />
          )}

          <Box
            mt={3}
            display="flex"
            justifyContent="space-between"
            width="100%"
          >
            <Button
              variant="outlined"
              sx={{
                borderColor: "var(--secondary-dark-color)",
                color: "var(--secondary-dark-color)",
                "&:hover": {
                  borderColor: "var(--secondary-dark-color)",
                  backgroundColor: "rgba(0, 0, 0, 0.05)",
                },
              }}
              onClick={handleDelete}
            >
              Delete
            </Button>
            <FormControlLabel
              control={
                <PinkSwitch
                  checked={isRequired}
                  onChange={handleSwitchChange}
                />
              }
              label="Required"
            />
          </Box>
        </Box>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={1000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />

      <AlertDialog 
       open={isDialogOpen}
       handleClose={handleDialogClose}
       title="Delete this question"
       message="Are you sure you want to delete question?"
       button1Text="Delete"
       button2Text="CANCEL"
       onButton1Click={handleConfirmDelete}
      />
    </Box>
  );
};

export default QuestionType;
