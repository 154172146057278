import React from "react";
import NavBar from "../components/elements/navBar/NavBar";
import HeroSection from "../components/landingPage/HeroSection";
import SuggestedForms from "../components/landingPage/SuggestedForms";
import StickyFooter from "../components/elements/Footer";

const LandingPage = () => {
  return (
    <>
      <NavBar />
      <HeroSection />
      <SuggestedForms />
      <StickyFooter />
    </>
  );
};

export default LandingPage;
