import React from "react";
import { Typography, Button, Grid, CardMedia } from '@mui/material';
import { Link } from 'react-router-dom';
import NavBar from "../components/elements/navBar/NavBar.js";
import StickyFooter from "../components/elements/Footer.js";

const NotFound = () => {
    return (
        <>
        <NavBar />
        <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ height: '92vh', padding: '50px 100px 50px 50px'}}>
                <Grid item xs={7} sx={{
                        padding: '10px 100px 10px 100px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                }}>
                    <Typography variant="h2" component="h1" sx={{ mb: 2, fontWeight: 'bold', color: 'var(--font-secondary-dark-color)' }}>
                      Page Not Found
                    </Typography>
                    <Typography variant="h6" component="h2" sx={{ mb: 3 }}>
                        Sorry, the page you are looking for does not exist.
                    </Typography>
                    <Link to="/" >
                        <Button variant="contained" 
                                sx={{ 
                                    backgroundColor: 'var(--secondary-dark-color)',
                                    '&:hover': {
                                        backgroundColor: 'var(--primary-dark-color)', 
                                        color: 'var(--font-secondary-dark-color)',
                                    },
                                    borderRadius: '20px',
                                    fontSize: '18px',
                                    fontWeight: 'semi-bold'
                                 }}
                        >
                            Back to home
                        </Button>
                    </Link>
                </Grid>
                <Grid item xs={5}
                    sx={{
                        padding: '10px 10px 10px 10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                }}>
                    <CardMedia
                        component="img"
                        alt='forms image'
                        image='images/404.svg'
                        sx={{
                            width: '80%',
                            height: '100%',
                        }}
                    />
                </Grid>
            </Grid>
            <StickyFooter />
        </>
    );
} 

export default NotFound;