import apiClient from "./apiClient";
import { getMyForms } from "./formService";

export const getUserData = async () => {
  try {
    const my_forms = await getMyForms();
    const number_of_my_forms = my_forms.length;

    const response_responses = await apiClient.get("/answers");
    const responses = response_responses.data;

    let totalQuestions = 0;
    let totalRequiredQuestions = 0;

    const id_of_my_forms = my_forms.map((form) => form._id);

    let number_of_my_answered_forms = 0;
    my_forms.forEach((form) => {
      if (form.response_counter > 0) {
        number_of_my_answered_forms++;
      }
    });

    const questionTypeCounts = {
      radio: 0,
      checkbox: 0,
      textfield: 0,
      data: 0,
      number: 0,
    };

    my_forms.forEach((form) => {
      totalQuestions += form.questions.length;
      totalRequiredQuestions += form.questions.filter((q) => q.required).length;
      form.questions.forEach((question) => {
        if (questionTypeCounts.hasOwnProperty(question.q_type)) {
          questionTypeCounts[question.q_type]++;
        }
      });
    });

    let responses_on_my_forms = [];
    id_of_my_forms.forEach((i) => {
      responses.forEach((r) => {
        if (r.form_id.toString() === i) {
          responses_on_my_forms.push(r);
        }
      });
    });

    let number_of_responses_on_my_forms = 0;
    responses_on_my_forms.forEach((answer) => {
      number_of_responses_on_my_forms++;
    });

    const questionTypeAnsweredCounts =
      responses_on_my_forms.length > 0
        ? most_common_answered_q(responses_on_my_forms, my_forms)
        : 0;

    const average_questions_per_form =
      my_forms.length > 0
        ? Math.round((totalQuestions / my_forms.length).toFixed(2))
        : 0;
    const average_required_questions_per_form =
      my_forms.length > 0
        ? Math.round((totalRequiredQuestions / my_forms.length).toFixed(2))
        : 0;
    const average_responses_per_form =
      my_forms.length > 0
        ? Math.round(number_of_responses_on_my_forms / my_forms.length)
        : 0;

    const pieData = Object.keys(questionTypeCounts).map((key) => ({
      label: key,
      value: questionTypeCounts[key],
    }));

    const pieDataAnswered = Object.keys(questionTypeAnsweredCounts).map(
      (key) => ({
        label: key,
        value: questionTypeAnsweredCounts[key],
      })
    );

    const userData = [
      {
        //0
        text: "my forms",
        value: number_of_my_forms,
      },
      {
        //1
        text: "my answered forms",
        value: number_of_my_answered_forms,
      },
      {
        //2
        text: "responses on my forms",
        value: number_of_responses_on_my_forms,
      },
      {
        //3
        text: "the most common type of question on my form",
        value: pieData,
      },
      {
        //4
        text: "questions on my form",
        value: average_questions_per_form,
      },
      {
        //5
        text: "required questions on my form",
        value: average_required_questions_per_form,
      },
      {
        //6
        text: "responses on my form",
        value: average_responses_per_form,
      },
      {
        //7
        text: "the most common question type answered on my form",
        value: pieDataAnswered,
      },
    ];

    return userData;
  } catch (error) {
    throw new Error("Error  forms data.");
  }
};

const removeDuplicates = (data) => {
  const seen = new Map();

  data.forEach((item) => {
    const q_id = item.q_id.trim();
    if (!seen.has(q_id)) {
      seen.set(q_id, {
        q_id,
        answer_text_length: item.answer_text.length,
      });
    }
  });
  return Array.from(seen.values());
};

const most_common_answered_q = (responses, forms) => {
  const questionTypeAnsweredCounts = {
    radio: 0,
    checkbox: 0,
    textfield: 0,
    data: 0,
    number: 0,
  };

  responses.forEach((response) => {
    try {
      const d = {
        f_id: response.form_id,
        q_id_and_r_lenght: removeDuplicates(response.answers),
      };

      const matchingForm = forms.find((form) => form._id.toString() === d.f_id);

      d.q_id_and_r_lenght.forEach((i) => {
        if (i.answer_text_length > 0) {
          const matchingQuestion = matchingForm.questions.find(
            (question) => question._id.toString() === i.q_id
          );

          if (matchingQuestion) {
            questionTypeAnsweredCounts[matchingQuestion.q_type]++;
          }
        }
      });
    } catch (err) {
      console.error("Error processing response:", err);
    }
  });

  return questionTypeAnsweredCounts;
};
