import { Box, TextField, Typography, Link, Alert, Button } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { register } from "../service/authService";
import AuthLayout from "../components/elements/AuthLayout";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const validateSignUp = () => {
    if (!email || !username || !password) {
      setFormErrors("Please enter your email, username, and password!");
      return false;
    }
    return true;
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    setFormErrors("");
    setSuccessMessage("");

    if (validateSignUp()) {
      try {
        const data = await register(email, username, password);
        setSuccessMessage(data.message);
        setTimeout(() => navigate("/signin"), 300);
      } catch (error) {
        setFormErrors(error.message);
        console.error("Error registering:", error);
      }
    }
  };

  return (
    <AuthLayout>
      {(buttonStyles) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Sign Up
          </Typography>

          <Box component="form" onSubmit={handleSignUp} sx={{ mt: 1 }}>
            {formErrors && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {formErrors}
              </Alert>
            )}
            {successMessage && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {successMessage}
              </Alert>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                "& .MuiInputLabel-root": {
                  color: "var(--mint-green-color)", // Label color
                },
                "& .MuiInputBase-root": {
                  color: "var(--mint-green-color)", // Input text color
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "var(--mint-green-color)", // Border color
                  },
                  "&:hover fieldset": {
                    borderColor: "var(--mint-green-color)", // Border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "var(--mint-green-color)", // Border color on focus
                  },
                },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              sx={{
                "& .MuiInputLabel-root": {
                  color: "var(--mint-green-color)", // Label color
                },
                "& .MuiInputBase-root": {
                  color: "var(--mint-green-color)", // Input text color
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "var(--mint-green-color)", // Border color
                  },
                  "&:hover fieldset": {
                    borderColor: "var(--mint-green-color)", // Border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "var(--mint-green-color)", // Border color on focus
                  },
                },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{
                "& .MuiInputLabel-root": {
                  color: "var(--mint-green-color)", // Label color
                },
                "& .MuiInputBase-root": {
                  color: "var(--mint-green-color)", // Input text color
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "var(--mint-green-color)", // Border color
                  },
                  "&:hover fieldset": {
                    borderColor: "var(--mint-green-color)", // Border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "var(--mint-green-color)", // Border color on focus
                  },
                },
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={buttonStyles}
            >
              Sign Up
            </Button>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Link
                href="/signin"
                underline="hover"
                sx={{ color: "var(--font-secondary-dark-color)" }}
              >
                Already have an account? Sign In
              </Link>
            </Box>
          </Box>
        </Box>
      )}
    </AuthLayout>
  );
};

export default SignUp;
