import apiClient from "./apiClient";

export const getAllForms = async (limit) => {
  try {
    const params = limit ? { _limit: limit } : {};
    const response = await apiClient.get("/forms", { params });
    return response.data;
  } catch (error) {
    throw new Error("Error loading forms data.");
  }
};

export const getTopForms = async (limit) => {
  try {
    const params = {
      _limit: limit,
    };
    const response = await apiClient.get("forms/topForms", { params });
    return response.data;
  } catch (error) {
    throw new Error("Error loading forms data.");
  }
};

export const getFormById = async (formId) => {
  try {
    const response = await apiClient.get(`/forms/${formId}`);
    return response.data;
  } catch (error) {
    throw new Error("Error loading form data.");
  }
};

export const getFormResponsesById = async (formId) => {
  try {
    const response = await apiClient.get(`/forms/${formId}/responses`);
    return response.data;
  } catch (error) {
    throw new Error("Error loading form responses.");
  }
};

export const getAverageTimeOfResponse = async (formId) => {
  try {
    const response = await apiClient.get(`/forms/${formId}/averageTime`);
    return response.data;
  } catch (error) {
    throw new Error("Error loading average time of response.");
  }
};

export const getMyForms = async () => {
  try {
    const response = await apiClient.get("/forms/myForms");
    return response.data;
  } catch (error) {
    return undefined;
  }
};

export const saveForm = async (formData) => {
  try {
    const response = await apiClient.post("/forms", formData);
    return response.data;
  } catch (error) {
    throw new Error("Error saving the form. Please try again.");
  }
};

export const updateForm = async (formId, formData) => {
  try {
    const response = await apiClient.patch(`/forms/${formId}`, formData);
    return response.data;
  } catch (error) {
    throw new Error("Error updating the form. Please try again.");
  }
};

export const submitFormData = async (formId, formData) => {
  try {
    const response = await apiClient.post(`/answers/${formId}`, formData);
    return response.data;
  } catch (error) {
    console.error(
      "Error submitting form:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const getPreviousForms = async () => {
  try {
    const response = await apiClient.get("/forms/previousForms");
    return response.data;
  } catch (error) {
    throw new Error("Error loading forms data.");
  }
};

export const deleteForm = async (formId) => {
  try {
    const response = await apiClient.delete(`/forms/${formId}`);
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data : "Error deleting the form."
    );
  }
};
