import { Box, Container, Paper, Typography, Link } from "@mui/material";
import { motion } from "framer-motion";

const AuthLayout = ({ children }) => {
  const buttonStyles = {
    mt: 3,
    mb: 2,
    padding: "10px 20px",
    backgroundColor: "var(--secondary-dark-color)",
    color: "var(--font-primary-light-color)",
    "&:hover": {
      backgroundColor: "var(--primary-dark-color)",
    },
    borderRadius: "10px",
  };

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        flexDirection: { xs: "column", sm: "row" },
        backgroundColor: "var(--primary-light-color)",
        position: "relative",
        overflow: "hidden",
        padding: { xs: "20px", sm: "0" },
        gap: { xs: "20px", sm: "0" },
      }}
    >
      <motion.div
        animate={{ scale: [1, 1.2, 1] }}
        transition={{ duration: 2, repeat: Infinity }}
        style={{
          position: "absolute",
          width: "200px",
          height: "200px",
          borderRadius: "50%",
          backgroundColor: "var(--secondary-light-color)",
          top: "-50px",
          left: "-50px",
          zIndex: 0,
        }}
      />
      <motion.div
        animate={{ scale: [1, 1.2, 1] }}
        transition={{ duration: 2, repeat: Infinity }}
        style={{
          position: "absolute",
          width: "200px",
          height: "200px",
          borderRadius: "50%",
          backgroundColor: "var(--secondary-light-color)",
          bottom: "-50px",
          right: "-50px",
          zIndex: 0,
        }}
      />

      <Box
        sx={{
          flex: 1,
          backgroundSize: "cover",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          zIndex: 1,
          px: { xs: 2, sm: 4 },
          py: { xs: 4, sm: 0 },
        }}
      >
        <Box
          sx={{
            color: "var(--font-primary-dark-color)",
            textAlign: "center",
            maxWidth: "80%",
            mx: "auto",
            px: { xs: 2, sm: 0 },
          }}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2 }}
            style={{ display: "inline-block" }}
          >
            <Link
              href="/"
              underline="hover"
              sx={{ color: "var(--font-secondary-dark-color)" }}
            >
              <Typography variant="h3" component="h1" sx={{ mb: 2 }}>
                Welcome to eMINT
              </Typography>
            </Link>
            <Typography
              variant="body1"
              sx={{ color: "var(--font-grey-dark-color)" }}
            >
              Join us to enjoy our amazing features and services.
            </Typography>
          </motion.div>
        </Box>
      </Box>

      <Box
        sx={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          zIndex: 1,
          px: { xs: 2, sm: 4 },
        }}
      >
        <Container maxWidth="sm">
          <Paper
            elevation={6}
            sx={{
              p: { xs: 2, sm: 4 },
              borderRadius: 3,
              backgroundColor: "#FFFFFF",
              boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.2)",
              maxWidth: "500px",
              width: "100%",
            }}
          >
            <Box component="div" sx={{ mt: 2, mb: 2 }}>
              {children(buttonStyles)}
            </Box>
          </Paper>
        </Container>
      </Box>
    </Box>
  );
};

export default AuthLayout;
