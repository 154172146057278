import React from 'react';
import Form from "../components/formFill/Form.js";
import NavBar from "../components/elements/navBar/NavBar.js";
import StickyFooter from "../components/elements/Footer.js";
import { Box } from '@mui/material';


const FormView = () => {
    return(
        <>
            <NavBar />
            <Box sx={{
                background: 'var(--linear-gradient)',
                minHeight: '100vh',
            }}>
                <Form />     
            </Box>
            <StickyFooter />
        </>
    );
}

export default FormView;