import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import FormPage from "./pages/FormPage.js";
import SignIn from "./pages/SignIn.js";
import SignUp from "./pages/SignUp.js";
import ResetPassword from "./pages/ResetPassword.js";
import LandingPage from "./pages/LandingPage.js";
import FormView from "./pages/FormView.js";
import BrowsePage from "./pages/BrowsePage.js";
import NotFound from "./pages/NotFoundPage.js";
import ResponsesView from "./pages/ResponsesView.js";
import { useSelector } from "react-redux";
import AdminPage from "./pages/AdminPage.js";
import UserPage from "./pages/UserPage.js";

function App() {

  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  
  function PrivateRoute({ element, isAuthenticated, isAdmin, adminRoute }) {
    if (adminRoute) {
      if (!isAdmin) {
        return <Navigate to="/" replace />;
      }
    }
    return isAuthenticated ? element : <Navigate to="/signin" replace />;
  }  

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/forms/:formId" element={<FormView />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          
          <Route path="/formPage" element={<PrivateRoute element={<FormPage />} isAuthenticated={isAuthenticated} />} />
          <Route path="/forms" element={<PrivateRoute element={<BrowsePage />} isAuthenticated={isAuthenticated} />} />
          <Route path="/forms/:formId/responses" element={<PrivateRoute element={<ResponsesView />} isAuthenticated={isAuthenticated}/>} />
          <Route path="/formPage/:formId"  element={<PrivateRoute element={<FormPage />} isAuthenticated={isAuthenticated} />} />

          <Route path="/user" element={<PrivateRoute element={<UserPage/>} isAuthenticated={isAuthenticated} /> } />
          <Route path="/admin" element={<PrivateRoute element={<AdminPage />} isAuthenticated={isAuthenticated} isAdmin={isAdmin} adminRoute={true} />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
