import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import FormPreview from "../FormPreview";
import FormBuilder from "../FormBuilder";
import AlertDialog from "../../elements/AlertDialog";

export default function Menu() {
  const [value, setValue] = useState(0);
  const { title, description, fields } = useSelector((state) => state.form);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const hasUnsavedChanges = fields.some((field) => !field.saved);

      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [fields]);

  useEffect(() => {
    const handlePopState = (event) => {
      const hasUnsavedChanges = fields.some((field) => !field.saved);

      if (hasUnsavedChanges) {
        event.preventDefault();
        setIsDialogOpen(true);
      }
    };

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [fields]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNavigationAttempt = (path) => {
    const hasUnsavedChanges = fields.some((field) => !field.saved);

    if (hasUnsavedChanges) {
      setIsDialogOpen(true);
    } else {
      navigate(path);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleBackConfrim = async () => {
    try {
      navigate("/forms");
    } catch (error) {
      console.error("Failed to go back:", error);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          zIndex: 10,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "white",
          borderBottom: "1px solid #ddd",
          padding: "8px 20px",
        }}
      >
        <Box sx={{
          display:'flex', 
          gap: '10px', 
          marginLeft: "20px",
          transition: "transform 0.3s ease",
          "&:hover": {
            transform: "scale(1.05) translateX(-2px)",
          },
        }}>
          <img src={'./ericsson2.svg'} alt="Logo" style={{ width: '20px', height: 'auto' }} />
          <Typography
            sx={{
              color: "var(--font-secondary-dark-color)",
              fontSize: "25px",
              fontWeight: "bold",
              cursor: "pointer",

            }}
            onClick={() => handleNavigationAttempt("/")}
          >
            Ericsson Nikola Tesla - ESG questionnaires
          </Typography>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            centered
            TabIndicatorProps={{
              style: {
                backgroundColor: "var(--font-secondary-dark-color)",
              },
            }}
            textColor="inherit"
            sx={{
              "& .MuiTab-root": {
                fontWeight: "bold",
                color: "var(--font-primary-dark-color)",
                minWidth: "120px",
              },
            }}
          >
            <Tab label="QUESTIONS" />
            <Tab label="PREVIEW" />
          </Tabs>
        </Box>

        <Typography
          sx={{
            color: "var(--font-secondary-dark-color)",
            fontSize: "15px",
            fontWeight: "bold",
            cursor: "pointer",
            marginRight: "20px",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "scale(1.05) translateX(2px)",
            },
          }}
          onClick={() => handleNavigationAttempt("/forms")}
        >
          BROWSE
        </Typography>
      </Box>

      {value === 0 && <FormBuilder />}
      {value === 1 && (
        <FormPreview title={title} description={description} fields={fields} />
      )}

      {showScrollToTop && (
        <Button
          onClick={scrollToTop}
          sx={{
            position: "fixed",
            bottom: 16,
            right: 20,
            backgroundColor: "var(--font-secondary-dark-color)",
            color: "white",
            "&:hover": {
              backgroundColor: "var(--font-secondary-dark-color)",
            },
            borderRadius: "50%",
            minWidth: "40px",
            minHeight: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ fontSize: "15px", fontWeight: "bold" }}>&uarr;</span>
        </Button>
      )}

      <AlertDialog
        open={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        title="Are you sure?"
        message="No changes will be saved when you go back."
        button1Text="Yes"
        onConfirm={handleBackConfrim}
      />
    </>
  );
}
