import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function CheckboxType ({ options, value, onChange }){

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        let updatedValue = [...value];

        if (checked) {
            if (!updatedValue.includes(name)) {
                updatedValue.push(name); 
            }
        } else {
            updatedValue = updatedValue.filter(option => option !== name); 
        }

        onChange(updatedValue); 
    };

    return(
        <FormGroup>
            {options.map((option, index)=> (
                <FormControlLabel 
                    key={index} 
                    control={
                        <Checkbox 
                            name={option.option_text}
                            checked={value.includes(option.option_text)}    
                            onChange={handleCheckboxChange}     
                        />
                    } 
                    label={option.option_text} 
                />
            ))}
        </FormGroup>
    )
}