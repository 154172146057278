import React from "react";
import { TextField } from "@mui/material";

export default function NumberType({ required, value, onChange }) {
  return (
    <TextField
      id="answer"
      label="Enter number"
      type="number"
      required={required === true}
      value={value}
      onChange={onChange}
      sx={{
        marginBottom: "20px",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "primary.main",
          },
          "&:hover fieldset": {
            borderColor: "primary.light",
          },
          "&.Mui-focused fieldset": {
            borderColor: "primary.dark",
          },
        },
        "& .MuiInputBase-input": {
          color: "text.primary",
        },
        "& .MuiFormLabel-root": {
          color: "text.secondary",
        },
        "& .MuiFormLabel-root.Mui-focused": {
          color: "primary.main",
        },
      }}
    />
  );
}
