import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, Typography, CardMedia } from "@mui/material";
import PrimaryButton from "../elements/buttons/primaryButton";
import AlertDialog from "../elements/AlertDialog";
import { resetForm } from "../../redux/slices/formSlice";


const HeroSection = () => {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleStartCreating = () => {
    if (!isAuthenticated) {
      setIsDialogOpen(true);
    } else {
      navigate("/FormPage");
      dispatch(resetForm());
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: { md: "60%", xs: "100%" },
        padding: "80px 50px 80px 50px",
      }}
    >
      <Grid
        container
        spacing={{ xs: 10, md: 2 }}
        justifyContent="center"
        alignItems="center"
        sx={{
          textAlign: { xs: "center", md: "left" },
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Grid
          item
          xs={12} 
          md={7} 
          sx={{
            display: { md: "block", xs: "flex" },
            flexDirection: { xs: "column" },
            alignItems: "center",
            justifyContent: "center",
            padding: { xs: "10px 20px", md: "10px 100px" },
            gap: "20px",
          }}
        >
          <Typography
            variant="h2"
            component="h1"
            sx={{
              mb: 2,
              fontWeight: "bold",
              color: "var(--font-secondary-dark-color)",
            }}
          >
            Ericsson Nikola Tesla - ESG questionnaires
          </Typography>
          <Typography
            variant="h4"
            component="h2"
            sx={{
              mb: 2,
              fontStyle: "italic",
              color: "var(--font-secondary-dark-color)",
            }}
          >
            Browse, fill out, and create forms effortlessly
          </Typography>
          <Typography variant="h6" component="h2" sx={{ mb: 3 }}>
            Welcome to Ericsson Nikola Tesla - ESG questionnaires, your go-to solution for efficient form management.
            Our app is designed to simplify the process of handling forms,
            whether you need to browse existing ones, fill them out, or create
            new forms from scratch.
          </Typography>

          <PrimaryButton
            text="Start Creating"
            onClick={handleStartCreating}
            fontsize={18}
          />
        </Grid>

        <Grid
          item
          xs={12} 
          md={5} 
          sx={{
            padding: "10px 10px 10px 10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CardMedia
            component="img"
            alt="forms image"
            image="images/forms.svg"
            sx={{
              width: { md: "80%", xs: "100%" },
              height: "100%",
            }}
          />
        </Grid>
      </Grid>
      {isDialogOpen && (
        <AlertDialog
          open={isDialogOpen}
          handleClose={handleClose}
          title="Sign in first!"
          message="Please sign in to start creating forms."
          button1Text="LogIn"
          button2Text="CANCEL"
        />
      )}
    </Box>
  );
};

export default HeroSection;
