import React from "react";
import { Button } from "@mui/material";

const PrimaryButton = ({ text, type, onClick, fontsize, disabled, icon }) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled ? disabled : false}
      type={type || "button"}
      variant="contained"
      endIcon={icon ? React.cloneElement(icon, { fontSize: "small" }) : null}
      sx={{
        backgroundColor: "var(--secondary-dark-color)",
        "&:hover": {
          backgroundColor: "var(--primary-dark-color)",
          color: "var(--font-secondary-dark-color)",
        },
        borderRadius: "20px",
        fontSize: `${fontsize}px`,
        fontWeight: "semi-bold",
      }}
    >
      {text}
    </Button>
  );
};

export default PrimaryButton;
