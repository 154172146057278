import React from "react";
import { Button } from "@mui/material";

const FilterButton = ({ text, onClick, isActive }) => {
    return (
        <Button
            onClick={onClick}
            sx={{
                cursor: "pointer",
                padding: "8px 16px",
                borderRadius: "20px",
                backgroundColor: isActive ? "var(--primary-dark-color)" : "var(--secondary-dark-color)" ,
                color: isActive ? "var(--font-secondary-dark-color)" : "var(--font-primary-light-color)",
                textTransform: "capitalize",
                "&:hover": {
                    backgroundColor: isActive ? "var(--primary-dark-color)" : "var(--secondary-light-color)",
                    color: isActive ? "var(--font-secondary-dark-color)" : "var(--font-secondary-dark-color)",
                },
            }}
        >
            {text}
        </Button>
    );
};

export default FilterButton;