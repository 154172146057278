import * as React from "react";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import PrimaryButton from "../elements/buttons/primaryButton";

const AlertDialog = ({ open, handleClose, title, message, button1Text, button2Text, onButton1Click, onButton2Click }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRedirect = () => {
    navigate("/forms");
  };

  const handleSignIn = () => {
    navigate("/SignIn");
  };

  const handleLogOut = async () => {
    try {
      localStorage.removeItem("authToken");
      dispatch(logoutUser());
      handleClose();
      navigate("/SignIn");
    } catch (error) {
      console.error("Error while logging out.", error);
    }
  };

  const handleButton1Click = () => {
    if (onButton1Click) {
      onButton1Click(); 
    } else if (button1Text === "LogIn") {
      handleSignIn();
    } else if (button1Text === "LogOut") {
      handleLogOut();
    } else {
      handleRedirect();
    }
    handleClose(); 
  };

  const handleButton2Click = () => {
    if (onButton2Click) {
      onButton2Click(); 
    }
    handleClose(); 
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleButton2Click}
          sx={{ color: "var(--font-secondary-dark-color)" }}
        >
          {button2Text || 'Cancel'}
        </Button>
        <PrimaryButton
          onClick={handleButton1Click}
          text={button1Text}
          fontsize={14}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
