import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Card,
  Menu,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router-dom";
import {
  getFormResponsesById,
  getAverageTimeOfResponse,
} from "../../service/formService";
import PieChartComponent from "../elements/charts/PieChart";
import BarChartComponent from "../elements/charts/BarChart";
import InfoCard from "./InfoCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import { IconButton } from "@mui/material";
import * as XLSX from "xlsx";
import PrimaryButton from "../elements/buttons/primaryButton";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const Response = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({});
  const [averageTime, setAverageTime] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const { formId } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const pdfRef = useRef();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportToExcel = () => {
    handleClose();
    exportToExcel();
  };

  const handleExportToPDF = () => {
    handleClose();
    exportToPDF();
  };

  useEffect(() => {
    const fetchFormData = async () => {
      setIsLoading(true);
      try {
        const response = await getFormResponsesById(formId);
        const time = await getAverageTimeOfResponse(formId);
        setFormData(response);
        setAverageTime(time);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchFormData();
  }, [formId]);

  const exportToPDF = () => {
    const input = pdfRef.current;
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = (canvas.height * pdfWidth) / imgWidth;

      let heightLeft = imgHeight;
      let position = pdfHeight - imgHeight;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, imgHeight);
      heightLeft -= pdfHeight;

      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
        heightLeft -= pdfHeight;
      }

      pdf.save(`Form_Responses_${formData.title}.pdf`);
    });
  };

  const exportToExcel = () => {
    if (formData && formData.questions && formData.questions.length > 0) {
      const data = [];
      const header = [
        "Username",
        "Email",
        "Submitted At",
        ...formData.questions.map((question) => `${question.q_text}`),
      ];
      data.push(header);

      formData.answers.forEach((answer) => {
        const row = [
          answer.user.username,
          answer.user.email,
          new Date(answer.submitted_at).toLocaleString(),
          ...formData.questions.map((question) => {
            const response = answer.answers.find(
              (ans) => ans.q_id === question.q_id
            );
            return response ? response.answer_text.join(", ") : "";
          }),
        ];
        data.push(row);
      });

      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Responses");
      XLSX.writeFile(wb, `Form_Responses_${formData.title}.xlsx`);
    } else {
      console.log("Podaci nisu učitani ili nema pitanja.");
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container sx={{ padding: "30px 0px" }} ref={pdfRef}>
      <Card
        sx={{
          padding: "30px 50px",
          textAlign: "center",
          position: "relative",
        }}
      >
        <Box sx={{ textAlign: "right", mb: 2 }}>
          <PrimaryButton
            onClick={handleClick}
            text="Export"
            icon={<DownloadIcon />}
          ></PrimaryButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleExportToExcel}>Export to Excel</MenuItem>
            <MenuItem onClick={handleExportToPDF}>Export to PDF</MenuItem>
          </Menu>
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "20px",
            left: "20px",
            display: "flex",
          }}
        >
          <IconButton
            aria-label="back"
            onClick={() => {
              const previousLocation = location.state?.from;
              if (previousLocation) {
                navigate(previousLocation);
              } else {
                navigate(`/forms`);
              }
            }}
          >
            <ArrowBackIcon sx={{ fontSize: 30 }} />
          </IconButton>
        </Box>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            color: "var(--font-secondary-dark-color)",
            fontWeight: "bold",
          }}
        >
          {formData.title}
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{
            color: "var(--font-grey-dark-color)",
            marginBottom: "20px",
          }}
        >
          {formData.description}
        </Typography>

        <InfoCard
          responses={formData.response_counter}
          averageTime={averageTime}
        />

        <Divider
          sx={{
            borderBottomWidth: 1,
            borderColor: "var(--font-secondary-dark-color)",
          }}
        />
        <Box align="left" sx={{ px: 10, pb: 3 }}>
          {formData.questions.map((question, qIndex) => {
            return (
              <Box
                key={qIndex}
                sx={{
                  pt: 3,
                  width: "100%",
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ pt: 3, color: "var(--font-secondary-dark-color)" }}
                >
                  {qIndex + 1}. {question.q_text}
                </Typography>

                {(() => {
                  switch (question.q_type) {
                    case "textfield":
                    case "number":
                    case "date":
                      return (
                        <List>
                          {question.responses.map((response, rIndex) => (
                            <React.Fragment key={rIndex}>
                              <ListItem>
                                <ListItemText
                                  primary={
                                    <>
                                      {question.q_type === "date" &&
                                      response.answer_text ? (
                                        new Date(
                                          response.answer_text
                                        ).toLocaleDateString(undefined, {
                                          year: "numeric",
                                          month: "long",
                                          day: "numeric",
                                        })
                                      ) : response.answer_text ? (
                                        response.answer_text
                                      ) : (
                                        <span
                                          style={{
                                            color: "gray",
                                            fontStyle: "italic",
                                          }}
                                        >
                                          No Answer
                                        </span>
                                      )}
                                      {response.count >= 1 && (
                                        <Typography
                                          variant="subtitle2"
                                          component="span"
                                          sx={{
                                            ml: 3,
                                            color:
                                              "var(--font-secondary-dark-color)",
                                            opacity: 0.7,
                                          }}
                                        >
                                          {response.count}{" "}
                                          {response.count === 1
                                            ? "Response"
                                            : "Responses"}
                                        </Typography>
                                      )}
                                    </>
                                  }
                                />
                              </ListItem>
                              {rIndex < question.responses.length - 1 && (
                                <Divider />
                              )}
                            </React.Fragment>
                          ))}
                        </List>
                      );

                    case "radio":
                      const pieData = question.responses.map((response) => ({
                        value: response.count,
                        label: response.answer_text,
                      }));
                      return (
                        <div style={{ width: "100%", height: "300px" }}>
                          <PieChartComponent pieData={pieData} />
                        </div>
                      );

                    case "checkbox":
                      const barData = question.responses.map((response) => ({
                        label: response.answer_text
                          ? response.answer_text
                          : "No Answer",
                        y: response.count,
                      }));
                      return (
                        <div style={{ width: "100%", height: "250px" }}>
                          <BarChartComponent
                            barData={barData}
                            fontSize={"7px"}
                            flag={true}
                          />
                        </div>
                      );
                    default:
                      return null;
                  }
                })()}
              </Box>
            );
          })}
        </Box>
      </Card>
    </Container>
  );
};

export default Response;
