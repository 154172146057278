import React from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  Card,
  Box,
  Divider,
  Container,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormGroup,
  Checkbox,
  TextareaAutosize,
} from "@mui/material";
import PrimaryButton from "../elements/buttons/primaryButton";

const FormPreview = () => {
  const { title, description, fields } = useSelector((state) => state.form);

  return (
    <Container sx={{ padding: "30px 0px" }}>
      <Card sx={{ padding: "30px 50px", textAlign: "center" }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ color: "var(--font-secondary-dark-color)", fontWeight: "bold" }}
        >
          {title}
        </Typography>

        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ color: "var(--font-grey-dark-color)", marginBottom: "20px" }}
        >
          {description}
        </Typography>

        <Divider
          sx={{
            borderBottomWidth: 1,
            borderColor: "var(--font-secondary-dark-color)",
          }}
        />

        {fields && (
          <Box align="left" sx={{ px: 10, pb: 3 }}>
            {fields.map((question, index) => (
              <div key={index}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ pt: 3, color: "var(--font-secondary-dark-color)" }}
                >
                  {index + 1}. {question.q_text ? question.q_text : <i>Enter your question</i>}
                  {question.required && (
                    <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
                  )}
                </Typography>

                {(() => {
                  switch (question.q_type) {
                    case "textfield":
                      return (
                        <TextareaAutosize
                          placeholder="Enter your answer"
                          type="text"
                          minRows={3}
                          maxRows={10}
                          style={{width:'100%', fontFamily: 'initial', fontSize:'18px'}}
                        />
                      );
                    case "radio":
                      return (
                        <RadioGroup>
                          {question.options.map((option) => (
                            <FormControlLabel
                              key={option.option_id}
                              value={option.option_text}
                              control={<Radio />}
                              label={option.option_text}
                            />
                          ))}
                        </RadioGroup>
                      );
                    case "checkbox":
                      return (
                        <FormGroup>
                          {question.options.map((option) => (
                            <FormControlLabel
                              key={option.option_id}
                              control={<Checkbox />}
                              label={option.option_text}
                            />
                          ))}
                        </FormGroup>
                      );
                    case "date":
                      return (
                        <TextField
                          type="date"
                        />
                      );
                    case "number":
                      return (
                        <TextField
                          label="Enter number"
                          type="number"
                        />
                      );
                    default:
                      return null;
                  }
                })()}
              </div>
            ))}
          </Box>
        )}
        <PrimaryButton text="Submit" disabled={true}/>
      </Card>
    </Container>
  );
};

export default FormPreview;
