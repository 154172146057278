import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import FormCard from "../elements/FormCard";
import { Typography, Box, Backdrop, CircularProgress } from "@mui/material";
import SecondaryButton from "../elements/buttons/secondaryButton";
import AlertDialog from "../elements/AlertDialog";
import { fetchTopForms } from "../../redux/slices/formSlice";

const SuggestedForms = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const forms = useSelector((state) => state.form.topForms);
  const loading = useSelector((state) => state.form.loading);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTopForms(6));
  }, [dispatch]);

  const handleAllForms = () => {
    if (!isAuthenticated) {
      setIsDialogOpen(true);
    } else {
      navigate("/forms");
    }
  };

  const handleFormClick = (formId) => {
    if (!isAuthenticated) {
      setIsDialogOpen(true);
    } else {
      navigate(`/forms/${formId}`, { state: { from: location } });
    }
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <Box
      sx={{
        padding: "50px 50px 50px 50px",
        backgroundColor: "var(--primary-light-color)",
      }}
    >
      <Typography
        variant="h4"
        component="h1"
        sx={{
          color: "var(--font-primary-dark-color)",
          fontWeight: "600",
        }}
      >
        Suggested Forms
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
            xl: "repeat(4, 1fr)",
          },
          rowGap: "50px",
          height: "fit-content",
          padding: "30px",
          columnGap: "20px",
          alignItems: "center",
          justifyItems: "center",
          wrap: "wrap",
          margin: "15px 0px 15px 0px",
        }}
      >
        {forms.map((form) => (
          <div
            key={form._id}
            onClick={() => handleFormClick(form._id)}
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
            <FormCard
              title={form.title}
              description={form.description}
              id={form._id}
              username={form.userId.username}
              count={form.response_counter}
            />
          </div>
        ))}
      </Box>
      <SecondaryButton text="All Forms  >>" onClick={handleAllForms} />
      {isDialogOpen && (
        <AlertDialog
          open={isDialogOpen}
          handleClose={handleClose}
          title="Sign in first!"
          message="Please sign in to see the details."
          button1Text="LogIn"
          button2Text="CANCEL"
        />
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading} // Koristimo loading stanje iz reduxa
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default SuggestedForms;
