import React from "react";
import Menu from "../components/formBuilder/navigation/Menu";
import { Box } from "@mui/material";

const FormPage = () => {
  return (
    <Box
      sx={{
        alignItems: "center",
        width: "100%",
        minHeight: "100vh",
        background:
          "var(--linear-gradient)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        paddingTop: "100px",
      }}
    >
      <Menu />
    </Box>
  );
};

export default FormPage;
