import { Card, Divider, Grid, Typography, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import NavBar from "../components/elements/navBar/NavBar.js" 
import { getAdminData } from "../service/adminService.js";
import BarChartComponent from "../components/elements/charts/BarChart.js";
import { experimentalStyled as styled } from "@mui/material/styles";
import PieChartComponent from "../components/elements/charts/PieChart.js";
import { Button } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  color: theme.palette.text.secondary,
  height: "100%",
}));

const ItemLabel = styled("div")({
  padding: "20px",
  color: "var(--font-primary-dark-color)",
  fontSize: "28px",
  fontWeight: "600",
  width: "100%",
  textAlign: "left",
});

const AdminPage = () => {
  const [adminData, setAdminData] = useState();
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const fetchData = async () => {
      const updatedAdminData = await getAdminData();
      setAdminData(updatedAdminData);
    };

    fetchData();

    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);

  }, []);

  return (
    <>
      <NavBar />
      <Card
        sx={{
          background:
            "var(--linear-gradient)",
          minHeight: "100vh",
          padding: "50px 100px",
        }}
      >
        <Card
          sx={{
            padding: "30px 50px",
            textAlign: "center",
            position: "relative",
            margin: "20px",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: "var(--font-secondary-dark-color)",
              fontWeight: "bold",
              paddingTop: { md: "0px", xs: "70px" },
              paddingBottom: "20px",
            }}
          >
            Track User Activity
          </Typography>
          <Divider
            sx={{
              borderBottomWidth: 1,
              borderColor: "var(--font-secondary-dark-color)",
            }}
          />

          {adminData &&
            (() => {
              return (
                <Grid
                  container
                  sx={{
                    padding: "50px 100px",
                    gap: "80px",
                    placeContent: "center",
                  }}
                >
                  <Grid item lg={10} xl={6}>
                    <Item>
                      <ItemLabel>Number of:</ItemLabel>
                      <BarChartComponent
                        fontSize={15}
                        barData={adminData
                          .slice(0, 3)
                          .map((i) => ({ label: i.text, y: i.value }))}
                      />
                    </Item>
                  </Grid>
                  <Grid item lg={6} xl={3}>
                    <Item>
                      <ItemLabel>Most common type of question:</ItemLabel>
                      <PieChartComponent pieData={adminData[8].value} />
                    </Item>
                  </Grid>
                  <Grid item lg={10} xl={6}>
                    <Item>
                      <ItemLabel>Number of :</ItemLabel>
                      <BarChartComponent
                        fontSize={15}
                        barData={adminData
                          .slice(6, 8)
                          .map((i) => ({ label: i.text, y: i.value }))}
                      />
                    </Item>
                  </Grid>
                  <Grid item lg={6} xl={3}>
                    <Item>
                      <ItemLabel>
                        Most common answered type of question:
                      </ItemLabel>
                      <PieChartComponent pieData={adminData[9].value} />
                    </Item>
                  </Grid>
                  <Grid item lg={10} xl={8}>
                    <Item>
                      <ItemLabel>Average number of :</ItemLabel>
                      <BarChartComponent
                        fontSize={15}
                        barData={adminData
                          .slice(3, 6)
                          .map((i) => ({ label: i.text, y: i.value }))}
                      />
                    </Item>
                  </Grid>
                </Grid>
              );
            })()}
        </Card>

        {showScrollToTop && (
          <Button
            onClick={scrollToTop}
            sx={{
              position: "fixed",
              bottom: 16,
              right: 20,
              backgroundColor: "var(--font-secondary-dark-color)",
              color: "white",
              "&:hover": {
                backgroundColor: "var(--font-secondary-dark-color)",
              },
              borderRadius: "50%",
              minWidth: "40px",
              minHeight: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span style={{ fontSize: "15px", fontWeight: "bold" }}>&uarr;</span>
          </Button>
        )}
      </Card>
    </>
  );
};

export default AdminPage;
