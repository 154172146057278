import React, { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }
  const truncated = text.substring(0, maxLength);
  const lastSpaceIndex = truncated.lastIndexOf(" ");

  if (lastSpaceIndex === -1) {
    return truncated + "...";
  }

  return text.substring(0, lastSpaceIndex) + "...";
};

const FormCard = ({
  title,
  description,
  id,
  count,
  username,
  onCardClick,
  onDelete,
  isMainPage = false,
}) => {
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(id);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    navigate(`/formPage/${id}`);
  };
  const location = useLocation();

  const currentUser = useSelector((state) => state.user.user?.username);

  const isOwner = currentUser === username;

  return (
    <Box
      onClick={onCardClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        border: "1px solid #ddd",
        padding: "20px",
        borderRadius: "15px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.3s ease-in-out",
        position: "relative",
        height: "250px",
        width: "300px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        "&:hover": {
          transform: "scale(1.1)",
          cursor: "pointer",
        },
        backgroundColor: "white",
      }}
    >
      {isMainPage && hover && isOwner && (
        <Box
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            padding: "5px",
            border: 1,
            borderRadius: "8px",
            borderColor: "var(--font-secondary-dark-color)",
            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.2)",
          }}
        >
          <IconButton
            onClick={handleDelete}
            size="small"
            sx={{ color: "var(--font-secondary-dark-color)" }}
          >
            <DeleteIcon />
          </IconButton>
          {count === 0 && (
            <IconButton
              onClick={handleEdit}
              sx={{ color: "var(--font-secondary-light-color)" }}
              size="small"
            >
              <EditIcon />
            </IconButton>
          )}
        </Box>
      )}
      <Box>
        <Typography
          variant="h6"
          component="h1"
          sx={{
            color: "var(--font-secondary-dark-color)",
            margin: "0px 0px 10px 0px",
            fontWeight: "600",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          component="h1"
          sx={{
            color: "var(--font-grey-dark-color)",
            margin: "0px 0px 20px 0px",
          }}
        >
          {truncateText(description, 100)}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <Box>
          <Typography
            variant="body2"
            sx={{
              color: "var(--font-grey-dark-color)",
            }}
          >
            Created by
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: isOwner
                ? "var(--font-secondary-dark-color)"
                : "var(--font-grey-dark-color)",
              fontWeight: "600",
            }}
          >
            {username}
          </Typography>
        </Box>

        <Box
          component="span"
          onClick={(e) => {
            if (isOwner && count > 0) {
              e.stopPropagation();
              navigate(`/forms/${id}/responses`, { state: { from: location } });
            }
          }}
          sx={{
            cursor: isOwner && count > 0 ? "pointer" : "default",
            color: isOwner
              ? count >= 0
                ? "var(--font-grey-dark-color)"
                : "var(--font-grey-light-color)"
              : "var(--font-grey-light-color)",
            pointerEvents: isOwner ? "auto" : "none",
            "&:hover": isOwner &&
              count >= 0 && {
                color: "var(--font-secondary-dark-color)",
                textDecoration: "underline",
              },
          }}
        >
          {count} responses
        </Box>
      </Box>
    </Box>
  );
};

export default FormCard;
