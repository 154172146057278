import React from 'react';
import { TextareaAutosize } from '@mui/material';

export default function TextFieldType ({ required, value, onChange }){
    return(
        <TextareaAutosize 
            id="answer" 
            placeholder="Enter your answer" 
            required={required === true}
            value={value}
            onChange={onChange}
            minRows={2}
            maxRows={5}
            style={{width:'100%', fontFamily: 'unset', fontSize:'18px'}}
        />
    )
}