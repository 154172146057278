import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(BarElement, Tooltip, Legend, CategoryScale, LinearScale);

const colorPalette = ['#A0C4A6', '#6B8E83', '#D3A4C7', '#8B6A7F', '#D1C0A9', '#AAB7B8', '#82C7C2', '#A9BFA6', '#BFD8D2', '#E1E8E8', '#9BCFCD', '#C1C7D0', '#D6E3F0'];

let currentColorIndex = 0;

const getNextPaletteColor = () => {
  const color = colorPalette[currentColorIndex];
  currentColorIndex = (currentColorIndex + 1) % colorPalette.length;
  return color;
};

const getResponsiveFontSize = () => {
  const viewportWidth = window.innerWidth; 
  const fontSize = Math.max(11, viewportWidth * 0.008);
  return fontSize;
};

const BarChartComponent = ({ barData, flag }) => {
  const [fontSize, setFontSize] = useState(getResponsiveFontSize());
  const [onResponsePage, setOnResponsePage] = useState(false)

  useEffect(() => {
    setOnResponsePage(flag)
    const handleResize = () => {
      setFontSize(getResponsiveFontSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const data = {
    labels: barData.map((item) => item.label),
    datasets: [
      {
        label: "Responses",
        data: barData.map((item) => item.y),
        backgroundColor: barData.map(() => getNextPaletteColor()),
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          font: {
            size: fontSize,
          },
          maxRotation: 0,
          minRotation: 0,
          autoSkip: false,
          callback: function(index) {
            let label = data.labels[index];
            let maxLength = 10;
            if (label.length > maxLength && onResponsePage) {
              return label.substr(0, maxLength) + '...';
            } else {
              return label;
            }
          }
        },
        offset: true,
        
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
      <Bar data={data} options={options} />
  );
};

export default BarChartComponent;
