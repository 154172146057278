import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Pagination, Stack, CircularProgress } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import FormCard from "../components/elements/FormCard.js";
import {
  fetchAllForms,
  fetchMyForms,
  fetchPreviousForms,
  setActiveFilter,
  setFilteredForms,
} from "../redux/slices/formSlice";
import { deleteForm } from "../service/formService.js";
import PrimaryButton from "../components/elements/buttons/primaryButton.js";
import FilterButton from "../components/elements/buttons/filterButton.js";
import SearchBar from "../components/elements/searchBar.js";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import NavBar from "../components/elements/navBar/NavBar.js";
import StickyFooter from "../components/elements/Footer.js";
import AlertDialog from "../components/elements/AlertDialog.js";

const BrowsePage = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const formsPerPage = isLargeScreen ? 8 : 6;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { forms, filteredForms, activeFilter, loading } = useSelector(
    (state) => state.form
  );
  const [currentPage, setCurrentPage] = React.useState(1);
  const [areThereForms, setAreThereForms] = React.useState(true);
  const [formToDelete, setFormToDelete] = React.useState(null);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const filterTags = [
    { text: "All Forms", function: "getAllForms" },
    { text: "My Forms", function: "getMyForms" },
    { text: "Previously Filled Out", function: "getPreviousForms" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      let response;
      switch (activeFilter) {
        case "getAllForms":
          response = await dispatch(fetchAllForms()).unwrap();
          break;
        case "getMyForms":
          response = await dispatch(fetchMyForms()).unwrap();
          setCurrentPage(1);
          break;
        case "getPreviousForms":
          response = await dispatch(fetchPreviousForms()).unwrap();
          break;
        default:
          response = [];
      }
      if (response.length === 0) {
        setAreThereForms(false);
        dispatch(setFilteredForms([]));
      } else {
        setAreThereForms(true);
        dispatch(setFilteredForms(response));
      }
    };

    fetchData();
  }, [dispatch, activeFilter]);

  const handleSearch = (searchTerm) => {
    if (searchTerm) {
      const searchResults = filteredForms.filter((form) =>
        form.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      dispatch(setFilteredForms(searchResults));
    } else {
      dispatch(setFilteredForms(forms));
    }
    setCurrentPage(1);
  };

  const handleClick = (function_name) => {
    dispatch(setActiveFilter(function_name));
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleDeleteConfirmation = async () => {
    try {
      await deleteForm(formToDelete);
      setIsDialogOpen(false);
      dispatch(fetchAllForms());
    } catch (error) {
      console.error("Error deleting form:", error);
    }
  };

  const handleOpenDialog = (formId) => {
    setFormToDelete(formId);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setIsDialogOpen(false);
  };

  const indexOfLastForm = currentPage * formsPerPage;
  const indexOfFirstForm = indexOfLastForm - formsPerPage;
  const currentForms = filteredForms.slice(indexOfFirstForm, indexOfLastForm);
  const pageCount = Math.ceil(filteredForms.length / formsPerPage);

  return (
    <>
      <NavBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "30px 0px",
          background:
            "var(--linear-gradient)",
          minHeight: "100vh",
        }}
      >
        <SearchBar onSearch={handleSearch} />

        <Box sx={{ display: "flex", gap: "10px", justifyContent: "center" }}>
          {filterTags.map((tag, index) => (
            <FilterButton
              key={index}
              text={tag.text}
              onClick={() => handleClick(tag.function)}
              isActive={activeFilter === tag.function}
            />
          ))}
        </Box>

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {areThereForms ? (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(1, 1fr)",
                    md: "repeat(2, 1fr)",
                    lg: "repeat(3, 1fr)",
                    xl: "repeat(4, 1fr)",
                  },
                  rowGap: "25px",
                  height: "fit-content",
                  padding: "40px",
                  columnGap: "20px",
                  alignItems: "center",
                  justifyItems: "center",
                  wrap: "wrap",
                }}
              >
                {currentForms.map((form, index) => (
                  <FormCard
                    key={index}
                    title={form.title}
                    description={form.description}
                    id={form._id}
                    count={form.response_counter}
                    username={form.userId.username}
                    onCardClick={() => navigate(`/forms/${form._id}`)}
                    onDelete={() => handleOpenDialog(form._id)}
                    isMainPage={true}
                  />
                ))}
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "20px",
                  padding: "100px 0px 100px 0px",
                }}
              >
                <Box>
                  {activeFilter !== "getPreviousForms"
                    ? "No forms available"
                    : "You didn't fill out any forms yet."}
                </Box>
                <Link
                  to={
                    activeFilter !== "getPreviousForms" ? "/FormPage" : "/forms"
                  }
                >
                  <PrimaryButton
                    text={
                      activeFilter !== "getPreviousForms"
                        ? "Start Creating"
                        : "Start Filling out"
                    }
                    onClick={() => handleClick("getAllForms")}
                  />
                </Link>
              </Box>
            )}
          </>
        )}

        <Box sx={{ flexGrow: 1 }} />
        <Stack spacing={2} sx={{ alignItems: "center", mb: 5 }}>
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={handlePageChange}
          />
        </Stack>
      </Box>
      <StickyFooter />

      <AlertDialog
        open={isDialogOpen}
        handleClose={handleCloseDialog}
        title="Delete Form"
        message="Are you sure you want to delete this form?"
        button1Text="Delete"
        button2Text="CANCEL"
        onButton1Click={handleDeleteConfirmation}
      />
    </>
  );
};

export default BrowsePage;
