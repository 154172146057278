import apiClient from "./apiClient";
import { jwtDecode } from "jwt-decode";

export const isTokenExpired = (token) => {
  if (!token) return true;

  const decodedToken = jwtDecode(token);
  if (!decodedToken) return true;

  const currentTime = Date.now() / 1000;
  return decodedToken.exp < currentTime;
};

export const resetPassword = async (email, newPassword) => {
  try {
    const response = await apiClient.put("/user/reset-password", {
      email,
      newPassword,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error("Error resetting password. Please try again.");
    }
  }
};

export const login = async (username, password) => {
  try {
    const response = await apiClient.post("/user/login", {
      username,
      password,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error("Error logging in. Please try again.");
    }
  }
};

export const register = async (email, username, password) => {
  try {
    const response = await apiClient.post("/user/signup", {
      email,
      username,
      password,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error("Error registering. Please try again.");
    }
  }
};
