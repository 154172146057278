import React from "react";
import Response from "../components/formResponses/Response.js";
import NavBar from "../components/elements/navBar/NavBar.js";
import StickyFooter from "../components/elements/Footer.js";
import {
  Container,
  Box,
  Card,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getFormResponsesById } from "../service/formService.js";
import PrimaryButton from "../components/elements/buttons/primaryButton.js";

const ResponsesView = () => {
  const { formId } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const response = await getFormResponsesById(formId);
        setFormData(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchFormData();
  }, [formId]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        background:
          "var(--linear-gradient)",
      }}
    >
      <NavBar />
      <Box sx={{ flex: "1 0" }}>
        {loading ? (
          <Box>
            <CircularProgress />
          </Box>
        ) : formData.response_counter ? (
          <Response />
        ) : (
          <Container sx={{ padding: "30px 0px" }}>
            <Card
              sx={{
                padding: "30px 50px",
                textAlign: "center",
              }}
            >
              <Typography>This form has 0 responses</Typography>
            </Card>

            <Box
              sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}
            >
              <PrimaryButton onClick={() => navigate(-1)} text="Go back" />
            </Box>
          </Container>
        )}
      </Box>
      <Box sx={{ flexShrink: 0 }}>
        <StickyFooter />
      </Box>
    </Box>
  );
};

export default ResponsesView;
