import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { login } from "../../service/authService";

export const loginUserAsync = createAsyncThunk(
  "user/loginUserAsync",
  async ({ username, password }, { rejectWithValue }) => {
    try {
      const response = await login(username, password);
      localStorage.setItem("authToken", response.token);
      localStorage.setItem("user", JSON.stringify(response.user));
      return response.user;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const token = localStorage.getItem("authToken");
const userFromStorage = localStorage.getItem("user");
const initialState = {
  user: userFromStorage ? JSON.parse(userFromStorage) : null,
  isAuthenticated: !!token,
  isAdmin: userFromStorage
    ? JSON.parse(userFromStorage).role === "admin"
    : false,
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logoutUser: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      state.isAdmin = false;
      localStorage.removeItem("authToken");
      localStorage.removeItem("user");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUserAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.isAuthenticated = true;
        state.isAdmin = action.payload.role === "admin";
      })
      .addCase(loginUserAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Login failed";
      });
  },
});

export const { logoutUser } = userSlice.actions;
export default userSlice.reducer;
