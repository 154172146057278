import React from "react";
import { Button } from "@mui/material";

const SecondaryButton = ({ text, onClick, fontsize }) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      sx={{
        backgroundColor: "var(--font-secondary-light-color)",
        fontWeight: "500",
        borderRadius: "20px",
        fontSize: `${fontsize}px`,
        textTransform: "capitalize",
        "&:hover": {
          backgroundColor: "var(--primary-dark-color)",
          color: "var(--font-secondary-dark-color)",
        },
      }}
    >
      {text}
    </Button>
  );
};

export default SecondaryButton;
