import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AlertDialog from '../AlertDialog'
import AccountMenu from "./accountMenu";
import { resetForm } from "../../../redux/slices/formSlice";

const NavBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const userName = localStorage.getItem("user");
  const location = useLocation();
  const dispatch = useDispatch();

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleLogout = () => {
    setIsDialogOpen(true);
  };

  const handleCreateNewForm = () => {
    dispatch(resetForm());
  };

  const menuItems = isAuthenticated
    ? [
        { to: "/", label: "Home" },
        { to: "/forms", label: "Browse" },
        {
          to: "/FormPage",
          label: "Create new form",
          onClick: handleCreateNewForm,
        },
      ]
    : [
        { to: "/SignIn", label: "Sign In" },
        { to: "/SignUp", label: "Sign Up" },
      ];

  const isActive = (path) => location.pathname === path;

  const renderMenuForSmallScreens = () =>
    menuItems.map((item, index) => (
      <MenuItem
        key={index}
        onClick={() => {
          handleMenuClose();
          item.onClick && item.onClick();
        }}
      >
        <Link
          to={item.to}
          style={{
            textDecoration: "none",
            color: isActive(item.to)
              ? "var(--font-secondary-light-color)"
              : "var(--font-secondary-dark-color)",
            borderBottom: isActive(item.to)
              ? "1px solid var(--font-secondary-light-color)"
              : "none",
          }}
        >
          {item.label}
        </Link>
      </MenuItem>
    ));

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 5,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "var(--secondary-dark-color)",
        padding: "8px 20px",
      }}
    >
      <Link to="/" style={{ textDecoration: "none" }}>
      <Box sx={{
        display:'flex', 
        gap: '10px', 
        transition: "transform 0.3s ease",
        "&:hover": {
          transform: "scale(1.05) translateX(-2px)",
        },
      }}>
        <img src="/ericsson.svg" alt="Logo" style={{ width: '20px', height: 'auto' }} />
        <Typography
          sx={{
            color: "#ffffff",
            fontSize: "25px",
            fontWeight: "bold",
          }}
        >
          Ericsson Nikola Tesla - ESG questionnaires
        </Typography>
      </Box>
        
      </Link>

      {isSmallScreen ? (
        <>
          <IconButton
            onClick={handleMenuClick}
            sx={{ color: "var(--font-secondary-dark-color)" }}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            sx={{
              "& .MuiPaper-root": {
                width: "100vw",
                backgroundColor: "var(--secondary-light-color)",
                color: "var(--font-primary-dark-color)",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                marginTop: "10px",
              },
              "& .MuiMenuItem-root": {
                padding: "12px 20px",
                "&:hover": {
                  backgroundColor: "var(--primary-light-color)",
                },
              },
            }}
          >
            {renderMenuForSmallScreens()}
          </Menu>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            gap: "30px",
            color: "var(--font-secondary-dark-color)",
            textTransform: "uppercase",
            alignItems: "center",
          }}
        >
          {menuItems.map((item, index) => (
            <Link
              key={index}
              to={item.to}
              onClick={() => {
                item.onClick && item.onClick();
              }}
              style={{ textDecoration: "none" }}
            >
              <Typography
                sx={{
                  color: isActive(item.to)
                    ? "var(--font-primary-light-color)"
                    : "var(--font-secondary-dark-color)",
                  borderBottom: isActive(item.to)
                    ? "1px solid var(--font-primary-light-color)"
                    : "none",
                  fontWeight: "bold",
                  "&:hover": {
                    color: isActive(item.to)
                      ? "var(--font-primary-light-color)"
                      : "var(--font-secondary-light-color)",
                    borderBottom: isActive(item.to)
                      ? "1px solid var(--font-primary-light-color)"
                      : "none",
                  },
                }}
              >
                {item.label}
              </Typography>
            </Link>
          ))}
          {isAuthenticated && (
            <AccountMenu userName={userName} handleLogOut={handleLogout} />
          )}
        </Box>
      )}
      {isDialogOpen && (
        <AlertDialog
          open={isDialogOpen}
          handleClose={handleClose}
          title="You are going to log out ☹"
          message="Are you sure you want to log out?"
          button1Text="LogOut"
          button2Text="CANCEL"
        />
      )}
    </Box>
  );
};

export default NavBar;
