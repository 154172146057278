import React from 'react';
import { TextField, Box } from '@mui/material';

function HeaderForm({ title, setTitle, description, setDescription }) {

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    return (
        <Box className='headerForm'>
            <TextField
                label="Form Title"
                variant="outlined"
                fullWidth
                value={title}
                onChange={handleTitleChange}
                margin="normal"
            />
            <TextField
                label="Form Description"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={description}
                onChange={handleDescriptionChange}
                margin="normal"
            />
        </Box>
    );
}

export default HeaderForm;
