import React, { useState, useEffect } from "react";
import { Snackbar, Alert, Box, IconButton } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  setTitle,
  setDescription,
  setFields,
  addField,
  updateField,
  removeField,
  fetchFormData,
} from "../../redux/slices/formSlice";
import QuestionType from "./QuestionType";
import HeaderForm from "./HeaderForm";
import "./FormBuilder.css";
import AlertDialog from "../elements/AlertDialog";
import PrimaryButton from "../elements/buttons/primaryButton";
import SecondaryButton from "../elements/buttons/secondaryButton";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { saveForm, updateForm, deleteForm } from "../../service/formService";

const FormBuilder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const pathParts = location.pathname.split("/");
  let formId = pathParts[pathParts.length - 1];

  const isValidId = /^[0-9a-fA-F]{24}$/.test(formId) || !isNaN(Number(formId));
  formId = isValidId ? formId : "";

  const { fields, title, description } = useSelector((state) => state.form);
  const [message, setMessage] = useState({ type: "", text: "" });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    if (formId) {
      dispatch(fetchFormData(formId));
    } else {
      if (fields.length === 0) {
        dispatch(
          setFields([
            {
              key: Date.now(),
              q_text: "",
              q_type: "textfield",
              options: [],
              required: false,
            },
          ])
        );
      }
    }
  }, [dispatch, fields.length, formId]);

  const handleAddField = () => {
    if (fields.length > 0 && !fields[fields.length - 1].q_text) {
      setMessage({
        type: "error",
        text: "Please complete the current question before adding a new one.",
      });
      return;
    }

    dispatch(addField());
  };

  const handleUpdateField = (index, updatedField) => {
    dispatch(updateField({ index, updatedField }));
  };

  const handleRemoveField = (index) => {
    dispatch(removeField(index));
  };

  const handleSave = async () => {
    if (!title || !description) {
      setMessage({
        type: "error",
        text: "Title and description are required.",
      });
      return;
    }

    const invalidQuestions = fields.some((field) => !field.q_text);
    if (invalidQuestions) {
      setMessage({ type: "error", text: "All questions must have text." });
      return;
    }

    const invalidOptions = fields.some(
      (field) =>
        field.options.length === 0 &&
        (field.q_type === "radio" || field.q_type === "checkbox")
    );
    if (invalidOptions) {
      setMessage({
        type: "error",
        text: "Questions with 'radio' or 'checkbox' type must have at least one option.",
      });
      return;
    }

    const removeTempIds = (fields) => fields.map(({ key, ...rest }) => rest);
    const sanitizedFields = removeTempIds(fields);

    const formData = {
      title,
      description,
      questions: sanitizedFields,
      created_at: new Date().toISOString(),
    };

    try {
      if (formId) {
        await updateForm(formId, formData);
        setIsDialogOpen(true);
        // setMessage({ type: "success", text: "Form updated successfully!" });
      } else {
        const data = await saveForm(formData);
        const newFormId = data._id;
        setMessage({ type: "success", text: "Form saved successfully!" });

        setTimeout(() => {
          navigate(`/forms/${newFormId}`);
        }, 500);
      }
    } catch (error) {
      setMessage({
        type: "error",
        text: `Error ${
          formId ? "updating" : "saving"
        } the form. Please try again.`,
      });
    } finally {
    }
  };

  const handleDelete = () => {
    setIsDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteForm(formId);
      navigate("/forms");
    } catch (error) {
      console.error("Failed to delete form:", error);
    }
  };

  const handleBack = () => {
    setIsDialogOpen(true);
  };

  const handleBackConfirm = async () => {
    try {
      navigate("/forms");
    } catch (error) {
      console.error("Failed to go back:", error);
    }
  };

  const handleCloseSnackbar = () => {
    setMessage({ type: "", text: "" });
  };

  return (
    <div className="formBuilder">
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton
          sx={{
            backgroundColor: "transparent",
            cursor: "default",
            "&:hover": {
              cursor: "pointer",
              backgroundColor: "transparent",
              color: "black",
            },
          }}
          aria-label="back"
          onClick={handleBack}
        >
          <ArrowBackIcon sx={{ fontSize: 30 }} />
        </IconButton>
        {formId && (
          <IconButton
            sx={{
              backgroundColor: "transparent",
              cursor: "default",
              "&:hover": {
                cursor: "pointer",
                backgroundColor: "transparent",
                color: "black",
              },
            }}
            aria-label="delete"
            onClick={handleDelete}
          >
            <DeleteIcon sx={{ fontSize: 30 }} />
          </IconButton>
        )}
      </Box>

      <Box>
        <HeaderForm
          title={title}
          setTitle={(newTitle) => dispatch(setTitle(newTitle))}
          description={description}
          setDescription={(newDescription) =>
            dispatch(setDescription(newDescription))
          }
        />
      </Box>

      {fields.map((field, index) => (
        <QuestionType
          key={field.key}
          index={index}
          fieldData={field}
          serialNumber={index + 1}
          onUpdate={(updatedField) => handleUpdateField(index, updatedField)}
          onRemove={() => handleRemoveField(index)}
        />
      ))}

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <PrimaryButton
          text="Add question"
          onClick={handleAddField}
          fontsize={14}
        />
        <SecondaryButton
          text={formId ? "Update" : "Save"}
          onClick={handleSave}
          fontsize={14}
        />
      </Box>

      <AlertDialog
        open={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        title="Delete Form"
        message="Are you sure you want to delete this form?"
        button1Text="Yes"
        onButton1Click={handleConfirmDelete}
      />

      <AlertDialog
        open={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        title="Are you sure?"
        message="No changes will be saved when you go back."
        button1Text="Yes"
        onConfirm={handleBackConfirm}
      />

      <AlertDialog
        open={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        title="FORM UPDATED"
        message="You have successfully updated the form! Go back to see all forms or update again."
        button1Text="SEE ALL FORMS"
        button2Text="Return"
        onButton1Click={handleBackConfirm}
        onButton2Click={() => setIsDialogOpen(false)}
      />

      <Snackbar
        open={!!message.text}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={message.type}>
          {message.text}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default FormBuilder;
