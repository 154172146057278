import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function RadioType({ options, value, onChange }) {
    return (
        <RadioGroup
            aria-labelledby="radio-buttons-group-label"
            name="radio-buttons-group"
            value={value} 
            onChange={(e) => onChange(e.target.value)} 
        >
            {options.map((option, index) => (
                <FormControlLabel
                    key={index}
                    value={option.option_text} 
                    control={<Radio />} 
                    label={option.option_text} 
                />
            ))}
        </RadioGroup>
    );
}
