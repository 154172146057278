import apiClient from "./apiClient";

export const getAdminData = async () => {
    try {
        const response_forms = await apiClient.get("/forms");
        const forms = response_forms.data;

        const response_users = await apiClient.get('/user');
        const users = response_users.data;

        const response_responses = await apiClient.get('/answers');
        const responses = response_responses.data;

        const number_of_forms = forms.length;
        const number_of_users = users.length;
        const number_of_responses = responses.length;

        let totalQuestions = 0;
        let totalRequiredQuestions = 0;

        const questionTypeCounts = {
            radio: 0,
            checkbox: 0,
            textfield: 0,
            data: 0,
            number: 0,
        };

        forms.forEach(form => {
            totalQuestions += form.questions.length;
            totalRequiredQuestions += form.questions.filter(q => q.required).length;
            form.questions.forEach(question => {
                if (questionTypeCounts.hasOwnProperty(question.q_type)) {
                    questionTypeCounts[question.q_type]++;
                }
            });
        });

        const uniqueFormIds = getUniqueFormIds(responses);
        let number_of_answered_forms = 0;
        if(uniqueFormIds) {
            uniqueFormIds.forEach(id => number_of_answered_forms++)
        }

        const questionTypeAnsweredCounts = most_common_answered_q(responses, forms);

        const average_questions_per_form = forms.length > 0 ? Math.round((totalQuestions / forms.length).toFixed(2)) : 0;
        const average_required_questions_per_form = forms.length > 0 ? Math.round((totalRequiredQuestions / forms.length).toFixed(2)) : 0;
        const average_responses_per_form = forms.length > 0 ? Math.round(number_of_responses / forms.length) : 0;
        
        const number_of_users_with_forms = [...new Set(forms.map(form => form.userId._id.toString()))];
        const number_of_users_without_forms = number_of_users - number_of_users_with_forms.length;
        
        const pieData = Object.keys(questionTypeCounts).map((key) => ({
            label: key,
            value: questionTypeCounts[key]
        }));

        const pieDataAnswered = Object.keys(questionTypeAnsweredCounts).map((key) => ({
            label: key,
            value: questionTypeAnsweredCounts[key]
          }));
        
        const adminData = [
            {
                //0
                text: 'forms',
                value: number_of_forms,
            },
            {
                //1
                text: 'answered forms',
                value: number_of_answered_forms,
            },
            {
                //2
                text: 'responses',
                value: number_of_responses,
            },
            {
                //3
                text: 'questions per form',
                value: average_questions_per_form,
            },
            {
                //4
                text: 'required questions per form',
                value: average_required_questions_per_form,
            },
            {
                //5
                text: 'responses per form',
                value: average_responses_per_form,
            },
            {
                //6
                text: 'users',
                value: number_of_users,
            },
            {
                //7
                text: 'users without forms',
                value: number_of_users_without_forms,
            },
            {
                //8
                text: 'the most common type of question',
                value: pieData,
            },
            {
                //9
                text: 'the most common question type answered',
                value: pieDataAnswered,
            },
        ]

        return adminData;
    } catch (error) {
      throw new Error("Error loading forms data.");
    }
};

const getUniqueFormIds = (responses) => {
    const formIdsSet = new Set();
    responses.forEach(response => {
        formIdsSet.add(response.form_id);
    });
    return Array.from(formIdsSet);
};

const removeDuplicates = (data) => {
    const seen = new Map();

    data.forEach(item => {
        const q_id = item.q_id.trim(); 
        if (!seen.has(q_id)) {
            seen.set(q_id, {
                q_id,
                answer_text_length: item.answer_text.length
            });
        }
    });
    return Array.from(seen.values());
};

const most_common_answered_q = (responses, forms) => {
    const questionTypeAnsweredCounts = {
        radio: 0,
        checkbox: 0,
        textfield: 0,
        data: 0,
        number: 0,
    };

    responses.forEach((response) => {
        try {
            const d = {
                f_id: response.form_id,
                q_id_and_r_lenght: removeDuplicates(response.answers), 
            };

            const matchingForm = forms.find(form => form._id.toString() === d.f_id);

            d.q_id_and_r_lenght.forEach((i) => {
                if (i.answer_text_length > 0) {
                    const matchingQuestion = matchingForm.questions.find(question => question._id.toString() === i.q_id);

                    if (matchingQuestion) {
                        questionTypeAnsweredCounts[matchingQuestion.q_type]++;
                    }
                }
            });

        } catch (err) {
            console.error('Error processing response:', err);
        }
    });

    return questionTypeAnsweredCounts;
}