import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Typography,
  Container,
  Card,
  Box,
  Divider,
  Tooltip,
  IconButton,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import {
  getFormById,
  submitFormData,
  deleteForm,
} from "../../service/formService";
import RadioType from "./questionTypes/RadioType";
import TextFieldType from "./questionTypes/TextFieldType";
import CheckboxType from "./questionTypes/CheckboxType";
import DateType from "./questionTypes/DateType";
import NumberType from "./questionTypes/NumberType";
import AlertDialog from "../elements/AlertDialog";
import PrimaryButton from "../elements/buttons/primaryButton";
import SecondaryButton from "../elements/buttons/secondaryButton";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";

const Form = () => {
  const { formId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [form, setForm] = useState(null);
  const [formData, setFormData] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [hasResponses, setHasResponses] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Novo stanje za praćenje učitavanja

  const fetchForm = async () => {
    try {
      const data = await getFormById(formId);
      const now = new Date().toISOString();

      setForm(data);
      setFormData({
        username: data.username,
        isOwner: data.isOwner,
        form_id: formId,
        started_at: now,
        submitted_at: "",
        answers: data.questions.map((question) => ({
          q_id: question._id,
          answer_text: question.q_type === "radio" ? "" : "",
        })),
      });

      setHasResponses(data.response_counter > 0);
    } catch (error) {
      console.error("Error fetching form:", error);
    } finally {
      setIsLoading(false); // Postavljamo na false kad završi učitavanje
    }
  };

  useEffect(() => {
    fetchForm();
  }, [formId]);

  const handleInputChange = (questionIndex, value) => {
    const updatedAnswers = [...formData.answers];
    updatedAnswers[questionIndex] = {
      ...updatedAnswers[questionIndex],
      answer_text: value,
    };

    setFormData((prevData) => ({
      ...prevData,
      answers: updatedAnswers,
    }));
  };

  const handleReturn = () => {
    navigate("/forms");
  };

  const handleDelete = () => {
    setIsDeleteClicked(true);
  };

  const validateForm = () => {
    if (!formData) return false;

    const isValid = form.questions.every((question, index) => {
      if (question.required) {
        const answer = formData.answers[index].answer_text;
        if (Array.isArray(answer)) {
          return answer.length > 0;
        } else {
          return answer.trim() !== "";
        }
      }
      return true;
    });

    if (!isValid) {
      setSnackbarMessage("Please fill out required fields.");
      setSnackbarOpen(true);
    }

    return isValid;
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const now = new Date().toISOString();
    const updatedFormData = {
      ...formData,
      submitted_at: now,
    };

    try {
      await submitFormData(formId, updatedFormData);
      setFormSubmitted(true);
    } catch (error) {
      console.error(
        "Error submitting form:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const handleFillOutAgain = () => {
    setFormData({
      isOwner: form.isOwner,
      form_id: formId,
      started_at: new Date().toISOString(),
      submitted_at: "",
      answers: form.questions.map((question) => ({
        q_id: question._id,
        answer_text: question.q_type === "radio" ? "" : "",
      })),
    });
    setFormSubmitted(false);
    fetchForm();
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteForm(formId);
      navigate("/forms");
    } catch (error) {
      console.error("Failed to delete form:", error);
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  else if (!form) return null;

  else return (
    <Container sx={{ padding: "30px 0px" }}>
      <Card
        sx={{
          padding: "30px 50px",
          textAlign: "center",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "20px",
            left: "20px",
            display: "flex",
          }}
        >
          <IconButton
            aria-label="back"
            onClick={() => {
              const previousLocation = location.state?.from;
              if (previousLocation) {
                navigate(previousLocation);
              } else {
                navigate(`/forms`);
              }
            }}
          >
            <ArrowBackIcon sx={{ fontSize: 30 }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "20px",
            right: "20px",
          }}
        >
          {formData?.isOwner && (
            <Box
              sx={{
                position: "absolute",
                top: "10px",
                right: "1px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <SecondaryButton
                style={{
                  fontSize: "12px",
                  borderRadius: "30px",
                  marginRight: "5px",
                  padding: "10px",
                }}
                onClick={() =>
                  navigate(`responses`, { state: { from: location } })
                }
                text="RESPONSES"
              />

              {!hasResponses ? (
                <Tooltip
                  title={
                    hasResponses
                      ? "This form cannot be edited because it has responses."
                      : ""
                  }
                  arrow
                  placement="top"
                >
                  <span>
                    <IconButton
                      aria-label="edit"
                      onClick={() => navigate(`/formPage/${formId}`)}
                      disabled={hasResponses}
                      sx={{ color: hasResponses ? "grey" : "inherit" }}
                    >
                      <EditIcon sx={{ fontSize: 30 }} />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : (
                <IconButton
                  sx={{
                    backgroundColor: "transparent",
                    cursor: "default",
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "transparent",
                      color: "black",
                    },
                  }}
                  aria-label="delete"
                  onClick={handleDelete}
                >
                  <DeleteIcon sx={{ fontSize: 30 }} />
                </IconButton>
              )}
            </Box>
          )}
        </Box>

        <Typography
          variant="h4"
          gutterBottom
          sx={{
            color: "var(--font-secondary-dark-color)",
            fontWeight: "bold",
            paddingTop: { md: "70px", xs: "70px" },
          }}
        >
          {form.title}
        </Typography>

        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ color: "var(--font-grey-dark-color)", marginBottom: "40px" }}
        >
          {form.description}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
        {form.questions.some((question) => question.required) && (
        <Typography
          variant="body2"
          sx={{
            color: "#6c757d",
            textAlign: "left",
            alignSelf: "start",
          }}
        >
          <span style={{ color: "red" }}>*</span> Required to fill
        </Typography>
      )}
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{ color: "var(--font-grey-dark-color)"}}
        >
          Author: {form.username}
        </Typography>
        </Box>
  
        <Divider
          sx={{
            borderBottomWidth: 1,
            borderColor: "var(--font-secondary-dark-color)",
          }}
        />

        <form onSubmit={handleSubmit}>
          <Box align="left" sx={{ px: 10, pb: 3 }}>
            {form.questions.map((question, index) => (
              <div key={index}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ pt: 3, color: "var(--font-secondary-dark-color)" }}
                >
                  {index + 1}. {question.q_text}
                  {question.required && (
                    <span style={{ color: "red", marginLeft: "5px" }}>*</span>
                  )}
                </Typography>
                {(() => {
                  switch (question.q_type) {
                    case "textfield":
                      return (
                        <TextFieldType
                          required={question.required}
                          value={formData.answers[index].answer_text}
                          onChange={(e) =>
                            handleInputChange(index, e.target.value)
                          }
                        />
                      );
                    case "radio":
                      return (
                        <RadioType
                          required={question.required}
                          options={question.options}
                          value={formData.answers[index].answer_text}
                          onChange={(value) => handleInputChange(index, value)}
                        />
                      );
                    case "checkbox":
                      return (
                        <CheckboxType
                          required={question.required}
                          options={question.options}
                          value={formData.answers[index].answer_text}
                          onChange={(selectedOptions) =>
                            handleInputChange(index, selectedOptions)
                          }
                        />
                      );
                    case "date":
                      return (
                        <DateType
                          required={question.required}
                          value={formData.answers[index].answer_text}
                          onChange={(e) =>
                            handleInputChange(index, e.target.value)
                          }
                        />
                      );
                    case "number":
                      return (
                        <NumberType
                          value={formData.answers[index].answer_text}
                          onChange={(e) =>
                            handleInputChange(index, e.target.value)
                          }
                        />
                      );
                    default:
                      return null;
                  }
                })()}
              </div>
            ))}
          </Box>

          {formData?.isOwner ? (
            <Box
              sx={{
                padding: "30px",
                backgroundColor: "#f8d7da",
                borderRadius: "8px",
                color: "#721c24",
                textAlign: "center",
              }}
            >
              <Typography variant="h6">
                You are the owner of this form and cannot fill it out.
              </Typography>
            </Box>
          ) : (
              <PrimaryButton text={"Submit"} type={"submit"} />
          )}


            {formSubmitted && (
              <AlertDialog
                open={formSubmitted}
                handleClose={() => setFormSubmitted(false)}
                title="Successfully submitted"
                message="You have successfully submitted the form."
                button1Text="OK"
                button2Text="Fill out again"
                onButton1Click={handleReturn}
                onButton2Click={handleFillOutAgain}
              />
            )}
            {isDeleteClicked && (
              <AlertDialog
                open={isDeleteClicked}
                handleClose={() => setIsDeleteClicked(false)}
                title="Delete Form"
                message="Are you sure you want to delete this form?"
                button1Text="Delete"
                button2Text="CANCEL"
                onButton1Click={handleConfirmDelete}
              />
            )}
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={1000}
              onClose={handleSnackbarClose}
              message={snackbarMessage}
            />
          </form>
      </Card>
    </Container>
  );
};

export default Form;
