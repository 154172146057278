import React from 'react';
import { TextField } from '@mui/material';

export default function DateType ({ value, onChange}){
    return(
        <TextField
            id="answer"
            type='date'
            value={value}
            onChange={onChange}
        />
    )
}