import React from 'react';
import { Box, Typography, Grid, Divider } from '@mui/material';

const InfoCard = ({ responses, averageTime }) => {
  return (
    <Box
      sx={{
        padding: 2,
        display: 'flex',
        alignItems: 'center',
        margin: 'auto',
      }}
    >
      <Grid container spacing={2} justifyContent="space-around" alignItems="center">
        <Grid item xs={3} textAlign="center">
          <Typography variant="h5" color="var(--font-primary-dark-color)">
            {responses}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {responses === 1 ? "Response" : "Responses"}
          </Typography>
        </Grid>

        <Divider orientation="vertical" flexItem />

        <Grid item xs={3} textAlign="center">
          <Typography variant="h5" color="var(--font-primary-dark-color)">
            {averageTime}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Average time to complete
          </Typography>
        </Grid>

      </Grid>
    </Box>
  );
};

export default InfoCard;
