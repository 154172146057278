import {
  Box,
  TextField,
  Typography,
  Link,
  Alert,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthLayout from "../components/elements/AuthLayout";
import { useDispatch, useSelector } from "react-redux";
import { loginUserAsync } from "../redux/slices/userSlice";

const SignIn = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.user);

  const validateLogin = () => {
    if (!username || !password) {
      setFormErrors("Please enter your username and password!");
      return false;
    }
    return true;
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    setFormErrors("");
    setSuccessMessage("");

    if (validateLogin()) {
      const resultAction = await dispatch(
        loginUserAsync({ username, password })
      );
      if (loginUserAsync.fulfilled.match(resultAction)) {
        setSuccessMessage("Login successful!");
        navigate("/forms");
      } else if (loginUserAsync.rejected.match(resultAction)) {
        setFormErrors(resultAction.payload || "Error logging in.");
      }
    }
  };

  return (
    <AuthLayout>
      {(buttonStyles) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Sign In
          </Typography>
          <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
            {formErrors && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {formErrors}
              </Alert>
            )}
            {successMessage && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {successMessage}
              </Alert>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              sx={{
                "& .MuiInputLabel-root": {
                  color: "var(--mint-green-color)",
                },
                "& .MuiInputBase-root": {
                  color: "var(--mint-green-color)",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "var(--mint-green-color)",
                  },
                  "&:hover fieldset": {
                    borderColor: "var(--mint-green-color)", // Border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "var(--mint-green-color)", // Border color on focus
                  },
                },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{
                "& .MuiInputLabel-root": {
                  color: "var(--mint-green-color)", // Label color
                },
                "& .MuiInputBase-root": {
                  color: "var(--mint-green-color)", // Input text color
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "var(--mint-green-color)", // Border color
                  },
                  "&:hover fieldset": {
                    borderColor: "var(--mint-green-color)", // Border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "var(--mint-green-color)", // Border color on focus
                  },
                },
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={buttonStyles}
            >
              Sign In
            </Button>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Link
                href="/signup"
                underline="hover"
                sx={{ mb: 2, color: "var(--font-secondary-dark-color)" }}
              >
                Don't have an account? Sign Up
              </Link>
              <Link
                href="/reset-password"
                underline="hover"
                sx={{ color: "var(--font-secondary-dark-color)" }}
              >
                Reset Password
              </Link>
            </Box>
          </Box>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      )}
    </AuthLayout>
  );
};

export default SignIn;
