import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const colorPalette = ['#A0C4A6', '#6B8E83', '#D3A4C7', '#8B6A7F', '#D1C0A9', '#AAB7B8', '#82C7C2', '#A9BFA6', '#BFD8D2', '#E1E8E8', '#9BCFCD', '#C1C7D0', '#D6E3F0'];

let currentColorIndex = 0;

const getNextPaletteColor = () => {
  const color = colorPalette[currentColorIndex];
  currentColorIndex = (currentColorIndex + 1) % colorPalette.length;
  return color;
};

const PieChartComponent = ({ pieData }) => {
  const data = {
    labels: pieData.map((item) => item.label),
    datasets: [
      {
        label: "Responses",
        data: pieData.map((item) => item.value),
        backgroundColor: pieData.map(() => getNextPaletteColor()),
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          boxWidth: 20,
          padding: 15,
        },
        align: "center",
      },
    },
  };

  return (
      <Pie data={data} options={options} />
  );
};

export default PieChartComponent;
