import { Box, TextField, Typography, Link, Alert, Button } from "@mui/material";
import { useState } from "react";
import { resetPassword } from "../service/authService";
import AuthLayout from "../components/elements/AuthLayout";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const validateReset = () => {
    if (!email || !newPassword || !confirmPassword) {
      setFormErrors("Please fill in all fields.");
      return false;
    }
    if (newPassword !== confirmPassword) {
      setFormErrors("Passwords do not match.");
      return false;
    }
    return true;
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    setFormErrors("");
    setSuccessMessage("");

    if (validateReset()) {
      try {
        const data = await resetPassword(email, newPassword);
        setSuccessMessage(data.message);
      } catch (error) {
        setFormErrors(error.message);
        console.error("Error resetting password:", error);
      }
    }
  };

  return (
    <AuthLayout>
      {(buttonStyles) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Reset Password
          </Typography>
          <Box component="form" sx={{ mt: 1 }} onSubmit={handleResetPassword}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="email"
              label="Email Address"
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                '& .MuiInputLabel-root': {
                  color: 'var(--mint-green-color)', // Label color
                },
                '& .MuiInputBase-root': {
                  color: 'var(--mint-green-color)', // Input text color
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'var(--mint-green-color)', // Border color
                  },
                  '&:hover fieldset': {
                    borderColor: 'var(--mint-green-color)', // Border color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'var(--mint-green-color)', // Border color on focus
                  },
                },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="New Password"
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              sx={{
                '& .MuiInputLabel-root': {
                  color: 'var(--mint-green-color)', // Label color
                },
                '& .MuiInputBase-root': {
                  color: 'var(--mint-green-color)', // Input text color
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'var(--mint-green-color)', // Border color
                  },
                  '&:hover fieldset': {
                    borderColor: 'var(--mint-green-color)', // Border color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'var(--mint-green-color)', // Border color on focus
                  },
                },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm New Password"
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              sx={{
                '& .MuiInputLabel-root': {
                  color: 'var(--mint-green-color)', // Label color
                },
                '& .MuiInputBase-root': {
                  color: 'var(--mint-green-color)', // Input text color
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'var(--mint-green-color)', // Border color
                  },
                  '&:hover fieldset': {
                    borderColor: 'var(--mint-green-color)', // Border color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'var(--mint-green-color)', // Border color on focus
                  },
                },
              }}
            />
            {formErrors && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {formErrors}
              </Alert>
            )}
            {successMessage && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {successMessage}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={buttonStyles}
            >
              Reset Password
            </Button>
            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
              <Link href="/signin" underline="hover" sx={{ color: 'var(--mint-green-color)' }}>
                Go back to Sign In
              </Link>
            </Typography>
          </Box>
        </Box>
      )}
    </AuthLayout>
  );
};

export default ResetPassword;
